body {
  min-height: 100%;
  min-width: 100vw;
  max-width: 100vw;
  margin: 0;
  overflow-x: hidden;
}

html {
  min-height: 100%;
  margin: 0;
  overflow-x: hidden;
}
a {
  color: #db3135 !important;
  text-decoration: none;
}
a:hover {
  color: #9c2829 !important;
}
